<!-- Max Database Calls - 3 -->

<!-- Thoughts -
Maybe use firstAddress property to avoid checking if it has a default address already set -->

<template>
  <div class="wrapper">
    <div class="container card material-card">
      <div class="row">
        <div class="col-12" style="padding-bottom: 0px;padding-top: 20px;">
          Where In {{ getUserLocation }}?
        </div>
        <div class="col-12">
          <p><strong>Locality </strong></p>

          <input
            v-if="selectedAddress.length == 0"
            v-bind:value="delivery_addr"
            @keyup="delivery_addr = $event.target.value"
            placeholder="Hatigaon, Survey, etc."
            type="text"
            style="width:100%;"
          />

          <div v-else class="flex-row align-items-center space-between">
            <span> {{ selectedAddress }}</span>
            <span class="text-primary">
              <small v-if="edit_address" @click="selectedAddress = ''"
                >Change</small
              >
            </span>
          </div>
        </div>
      </div>

      <div
        class="suggestions-box"
        v-if="selectedAddress.length == 0"
        style="background-color: #f5f6fa; padding:0px 15px;font-weight: bold;"
      >
        <div v-if="delivery_addr.length > 0" class="row">
          <div class="col-12">
            <div
              class="animated fadeIn faster"
              v-for="location in suggested_locations.slice(0, 3)"
              v-bind:key="location.refIndex"
            >
              <p>
                <button
                  class="remove-btn-styles"
                  v-on:click="selectLocation(location)"
                >
                  <i class="ion-navigate"></i> &nbsp; {{ location }},
                  Guwahati, Assam
                </button>
              </p>
            </div>
            <p
              v-if="delivery_addr.length >= 3 && !!suggested_locations.length"
              class="animated fadeIn faster text-right"
            >
              Can't find your location? &nbsp;
              <!-- <router-link
                class="cursor-pointer text-success"
                to="available-locations"
                >See all available</router-link
              > -->
              <span
                class="cursor-pointer text-success"
                @click="showCustomAddressModal = true"
                >Enter manually
              </span>
            </p>
          </div>
        </div>
        <div v-if="delivery_addr.length > 0 && !suggested_locations.length" style="padding-bottom:20px;;font-weight:normal;">
          <em>Suggestions will appear here... </em>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0">
          <p class=""><strong>Full Name</strong></p>
          <input
            v-if="edit_address"
            type="text"
            v-model="name"
            style="width:100%;"
          />
          <div v-else class="text-success">{{ name }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0">
          <p class=""><strong>Street Address</strong></p>
          <input
            v-if="edit_address"
            type="text"
            v-model="streetAddress"
            style="width:100%;"
          />
          <div v-else class="text-success">{{ streetAddress }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0">
          <p class=""><strong>Pincode</strong></p>
          <input
            v-if="edit_address"
            type="text"
            v-model.number="pincode"
            style="width:100%;"
          />
          <div v-else class="text-success">{{ pincode }}</div>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 pt-0">
          <p><strong>Mobile: </strong></p>
          <div v-if="edit_address" class="row no-gutters">
            <div class="col-2 center">
              <div style="padding: 7px 10px; font-size: 14px; height: 15px;">
                +91
              </div>
            </div>
            <div class="col-10">
              <input type="text" style="width:100%;" v-model.number="phone" pattern="[0-9]*" inputmode="numeric"/>
            </div>
          </div>
          <div v-else class="flex-row align-items-center space-between">
            <span class="text-success"> {{ phone }} </span>
            <span class="text-primary">
              <small v-if="edit_address">Change</small>
            </span>
          </div>
          <p v-show="!firstAddress" style="margin-bottom: 0px;">
            <input
              type="checkbox"
              name=""
              id="notifyCheckbox"
              v-model="checked"
            />
            <label for="notifyCheckbox"
              ><small class="grey">This is my number</small></label
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            <button
              @click="saveDeliveryLocation()"
              class="btn-wide text-white bg-primary"
            >
              SAVE LOCATION & CONTINUE &nbsp;<i class="icon-right"></i>
            </button>
          </p>
        </div>
      </div>
    </div>

    <custom-address-modal  v-if="showCustomAddressModal == true" @customLocalitySet="onCustomLocalitySet($event)" @close="showCustomAddressModal = false"/>
  </div>
</template>

<script>
import { bus } from "./../main";
import { db } from "./../database";
import AddressValidationMixin from "./../mixins/AddressValidationMixin";
import axios from "axios";
import CustomAddressModal from './../components/parts/CustomAddressModal.vue'

export default {
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("cart") != null) {
      var cart = JSON.parse(localStorage.getItem("cart"));
      if (cart.length == 1) {
        next("/menu");
      } else next();
    }
  },
  components: {
    CustomAddressModal
  },
  props: ["user"],
  data() {
    return {
      showCustomAddressModal: false,
      firstAddress: false,
      addressType: "",
      addNewAddress: false,
      checked: false,
      name: "",
      phone: "",
      streetAddress: "",
      pincode: null,
      edit_address: true,
      selectedAddress: "",
      delivery_addr: "",
      suggested_locations: "",
      isCustomLocality: false
      // locations: []
    };
  },

  watch: {
    delivery_addr: function() {
      if (this.delivery_addr.length >= 3) {
        // console.log('length more then three')
        // this.suggested_locations = [{ item: 'Hatigaon'}, {item:'Lalmati'}, {item:'Kahilipara'},{item:'Super Market'} ]
        axios
          .post("https://cherryfroststore.df.r.appspot.com/search", {
            query: this.delivery_addr,
          })
          .then((response) => {
            console.log(response.data);
            this.suggested_locations = response.data.results;
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        this.suggested_locations = [];
      }
    },
  },

  created() {
    bus.$emit('show_loading',true)    

    this.name = this.user.displayName;
    if (this.$route.query.firstAddress) {
      this.firstAddress = true;
      this.checked = true;
    }
    bus.$emit("show_loading", false);
  },
  methods: {
    onCustomLocalitySet(data) {
      this.isCustomLocality = true
      this.showCustomAddressModal = false
      this.delivery_addr = ""      
      this.selectedAddress = data
    },
    matchPlace() {
      let matchedPlaces = [];
      let place_lower = this.delivery_addr.toLowerCase();
      this.locations.forEach((element, index) => {
        let element_lower = element.toLowerCase();
        if (element_lower.startsWith(place_lower)) {
          matchedPlaces.push(element);
        }
      });
      return matchedPlaces;
    },
    selectLocation(location) {
      this.isCustomLocality = false      
      this.selectedAddress = location;
    },
    addDeliveryAddress() {
      this.edit_address = false;
    },
    saveDeliveryLocation() {
      const thisRef = this;
      if (this.checkAddress()) {                
        bus.$emit("show_loading", true);
        let addRef = db
          .collection("users/" + this.user.uid + "/addresses")
          .where("type", "==", 1);

        addRef
          .get()
          .then((querySnapshot) => {
            // remove default property from current default address. may need to use transactions
            if (querySnapshot.size != 0) {
              let docId = querySnapshot.docs[0].id;
              addRef = db
                .doc("users/" + this.user.uid + "/addresses/" + docId)
                .update({
                  type: 0,
                })
                .then(() => console.log("done"));
            }
          })
          .then(() => {
            let location = {
              name: this.name,
              city: this.getUserCity,
              location: this.getUserLocation,
              delivery_location: this.selectedAddress,
              streetAddress: this.streetAddress,
              pincode: this.pincode,
              phone: this.phone,
              type: 1,
              isCustomLocality: this.isCustomLocality
            };
            const addressesRef = db.collection(
              "users/" + this.user.uid + "/addresses"
            );

            // ADD THE LOCATION AT THE SERVER
            addressesRef.add(location).then(() => {
              let userDocRef = db.doc("users/" + thisRef.user.uid);
              if (thisRef.checked) {
                userDocRef
                  .update({
                    notify: thisRef.phone,
                  })
                  .then(() => {
                    this.$router.replace("/delivery-address");
                  })
                  .catch((err) => {
                    if (err.code == "not-found") {
                      console.log(
                        "user document not yet created. Creating new one..."
                      );
                      this.createNewUserDoc();
                    }
                  });
              } else {
                this.$router.replace("/delivery-address");
              }
            });
          });
      }
    },
    createNewUserDoc() {
      let userDocRef = db.doc("users/" + this.user.uid);
      userDocRef
        .set({
          name: this.user.displayName,
          email: this.user.email,
          notify: this.phone,
          token: [],
        })
        .then(() => {
          this.$router.replace("/delivery-address");
        })
        .catch((err) => {
          console.log(error.message);
        });
    },
  },
  mixins: [AddressValidationMixin],
};
</script>

<style scoped>
</style>
