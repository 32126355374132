<template>
  <div class="wrapper">
      <div class="container card material-card">                   
          <div class="row">
            <div class="col-12">
              <p><strong>Locality </strong></p>
              <input v-if="selectedAddress.length == 0" v-model="delivery_addr" placeholder=" Eg: Bhangagarh, Bhetapara" type="text" style="width:100%;" >
              <div v-else class="flex-row align-items-center space-between">
                <span> {{ selectedAddress }}</span>
                <span class="text-primary">
                  <small v-if="edit_address" @click="selectedAddress=''">Change</small>
                </span>
              </div>
            </div>          
          </div>
          <div class="suggestions-box" v-if="selectedAddress.length == 0">        
            <div v-if="delivery_addr.length > 0" class="row">
              <div class="col-12">
                <div class="animated fadeIn faster" v-for="(location,index) in matched_locations.slice(0, 3)" v-bind:key="`${location}-${index}`">                  
                  <p>
                    <button class="remove-btn-styles"  v-on:click="selectLocation(location)">
                      <i class="ion-navigate"></i> &nbsp; {{ location }}, Guwahati
                    </button>
                  </p>
                </div>
                <p
                  v-if="delivery_addr.length >= 3 && !!matched_locations.length"
                  class="animated fadeIn faster text-right"
                >
                  Can't find your location? &nbsp;
                  <span
                    class="cursor-pointer text-success"
                    @click="showCustomAddressModal = true"
                    >Enter manually
                  </span>
                </p>                
                  <!-- <p v-if="matched_locations.length == 0">
                     Sorry, no matches found. &nbsp; <router-link class="cursor-pointer text-success" to="available-locations" >See available?</router-link>
                  </p> -->
              </div>
            </div>
            <div v-if="delivery_addr.length > 0 && !matched_locations.length" style="padding-bottom:20px;;font-weight:normal;">
              <em>Suggestions will appear here... </em>
            </div>            
          </div>          
          <div class="row">
            <div class="col-12 pt-0">             
              <p class=""><strong>Full Name</strong></p> 
              <input v-if="edit_address" type="text" v-model="name" style="width:100%;" >            
              <div v-else class=" text-success">{{ name }}</div>
            </div>          
          </div>   
          <div class="row">
            <div class="col-12 pt-0">
              <p class=""><strong>Street Address</strong></p>
              <input
                v-if="edit_address"
                type="text"
                v-model="streetAddress"
                style="width:100%;"
              />
              <div v-else class="text-success">{{ streetAddress }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-0">
              <p class=""><strong>Pincode</strong></p>
              <input
                v-if="edit_address"
                type="text"
                
                v-model.number="pincode"
                style="width:100%;"
              />
              <div v-else class="text-success">{{ pincode }}</div>
            </div>
          </div>                 
          <div class="row ">
            <div class="col-12 pt-0">
              <p><strong>Mobile: </strong></p>              
              <div v-if="edit_address" class="row no-gutters">
                <div class="col-2 center">
                   <div style="padding: 7px 10px; font-size: 14px; height: 15px;"> +91</div>
                </div>
                <div class="col-10">
                  <input type="text" style="width:100%;" v-model.number="phone" pattern="[0-9]*" inputmode="numeric">
                </div>
              </div>
            </div>          
          </div>        
          <div class="row">
            <div class="col-12 pt-0">
              <p><button @click="saveDeliveryLocation()" class="btn-wide text-white bg-primary">SAVE</button></p>                       
            </div>          
          </div>       
    </div>

    <custom-address-modal  v-if="showCustomAddressModal == true" @customLocalitySet="onCustomLocalitySet($event)" @close="showCustomAddressModal = false"/>

  </div>
</template>


<script>

import {bus} from './../main'
import {db} from './../database'
import AddressValidationMixin from './../mixins/AddressValidationMixin'
import CustomAddressModal from './../components/parts/CustomAddressModal.vue'
import axios from "axios";


export default {
  props: ['user'],
  components: {
    CustomAddressModal
  },  
data(){
    return {
      addressType: '',
      addresses: [],
      addNewAddress: false,
      name: '',
      phone: this.user.phoneNumber,
      edit_address: true,
      selectedAddress: '',
      delivery_addr: '',
      pincode: null,
      streetAddress: '',
      suggested_locations: '',
      matched_locations: [],
      locations: [],
      isCustomLocality: false,
      showCustomAddressModal: false,
    }
  },
     watch:{
    delivery_addr : function(){
      
      if(this.delivery_addr.length >= 3){
        // console.log('length more then three')
         axios
        .post("https://cherryfroststore.df.r.appspot.com/search", {
          query: this.delivery_addr
        })
        .then((response) => {
          // console.log(response.data);
          this.matched_locations = response.data.results

        
        })
        .catch(function(error) {
          console.log(error);
        });
    }
      }
      
  },
  async created(){
    bus.$emit('show_loading',true)
    this.name = this.user.displayName;
    await this.getCurrentAddress()
  },  
  methods: {
    onCustomLocalitySet(data) {
      this.isCustomLocality = true
      this.showCustomAddressModal = false
      this.delivery_addr = ""      
      this.selectedAddress = data
    },    
    getCurrentAddress(){
      let addressRef = db.collection("users/"+this.user.uid+"/addresses")      
      addressRef.doc(this.$route.query.address).get().then((doc) => {
        let data = doc.data()
        this.phone = data.phone
        this.selectedAddress = data.delivery_location
        this.pincode = data.pincode
        this.streetAddress = data.streetAddress
        this.addressType = data.type
        bus.$emit('show_loading',false)          
      })
    },
    // matchLocation(){      
    //   this.suggested_locations = this.locations.filter(item => item.toLowerCase().startsWith(this.delivery_addr.toLowerCase()))      
    // },
    selectLocation(location){
      this.selectedAddress = location;
    },
    addDeliveryAddress(){
      this.edit_address = false;
    },
    saveDeliveryLocation() {     
      if(this.checkAddress()) {
        bus.$emit('show_loading',true)      
        let location = { "name": this.name, "city": this.getUserCity, "location": this.getUserLocation, "pincode": this.pincode, "delivery_location": this.selectedAddress , "streetAddress": this.streetAddress, "phone": this.phone, type: this.addressType, isCustomLocality: this.isCustomLocality }                    
        let addressRef = db.collection("users/"+this.user.uid+"/addresses")            
        addressRef.doc(this.$route.query.address)
        .set(location)      
        .then(()=>{
          this.$router.go(-1)
        }).catch((error)=>{
          console.log(error)
        })
      }        
    }, 
  },  
  mixins: [AddressValidationMixin]
}
</script>
