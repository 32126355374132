<!-- No database call required -->

<template>
  <div class="full-height">
    <div class="full-height" v-if="!isCartEmpty">
      <div class="container">
        <div
          class="cart-item-control"
          style="position:relative;z-index:3;margin-bottom:-30px;top:80px;display:inline-block;"
        >
          <div
            @click="scrollCartItemsDisplay(0)"
            class="flex-row dead-center material-card bg-white"
            style="height:30px;width:30px;border-radius:50%;opacity:0.7;"
          >
            <i class="icon-left"></i>
          </div>
        </div>
        <div
          class="cart-item-control"
          style="position:relative;z-index:3;margin-bottom:-30px;top:80px;display:inline-block;float:right;"
        >
          <div
            @click="scrollCartItemsDisplay(1)"
            class="flex-row dead-center material-card bg-white"
            style="height:30px;width:30px;border-radius:50%;opacity:0.7;"
          >
            <i class="icon-right"></i>
          </div>
        </div>
        <div
          class="container-fluid card"
          style="background-color:#f5f6fa;padding:0px !important;margin-top:-20px;margin-bottom:-10px;"
        >
          <div v-if="itemsUnavailable.length != 0" class="row no-gutters">
            <div class="col-12 text-primary">
              <span
                v-for="(item, index) in itemsUnavailable"
                :key="`item-${index}`"
              >
                {{ item }}
              </span>
              unavailable currently. Removed from cart
            </div>
          </div>
          <div class="row no-gutters" style="margin-right:0px; margin-left:0px">
            <div
              class="col-12"
              style="padding-bottom:0px !important;padding:0px 0px !important;"
            >
              <div
                ref="cartEl"
                id="cartItemsDisplay"
                class="flex-cart"
                style="margin-right:15px;margin-left:-15px;"
              >
                <div style="flex-basis:1px;"></div>
                <template v-for="(item, index) in getCartItems">
                  <div
                    class="yellow bg-white material-card"
                    :key="`cartItem-${index}`"
                  >
                    <div
                      class="flex-row justify-content-end"
                      v-on:click="deleteItemFromCart(item)"
                      style="margin-bottom:-30px;position:relative;top:-10px;left:10px;"
                    >
                      <span
                        class="bg-white flex-row dead-center material-card strong"
                        style="border-radius:50%;width:20px;height:20px;border:3px solid #f5f6fa;box-sizing: content-box;"
                      >
                        <span>X</span>
                      </span>
                    </div>
                    <div>
                      <img class="cart-item-image" :src="item.url" alt="" />
                    </div>
                    <div style="padding:10px 10px 0px 10px;">
                      <p
                        style="flex-grow:2;font-size:14px;letter-spacing:1px;text-align:left;"
                      >
                        <strong>{{ item.name }}</strong>
                        <br />
                        <small style="display:inline-block;margin-top:5px;"
                          >{{ item.flavour }} {{ item.weight }}
                        </small>
                      </p>
                      <div style="text-align:left;font-size:16px;">
                        &#8377;{{ item.price * item.qty }}
                      </div>
                    </div>
                    <div>
                      <template v-if="item.subtype == 'Bakery'">
                        <router-link
                          v-if="!item.message"
                          v-bind:to="'/add-message/' + cart.indexOf(item) + '?category='+item.category"
                        >
                          <p>
                            <button
                              class="btn-success"
                              style="border-radius:5px;"
                            >
                              <small>
                                <i class="icon-plus"></i>
                                &nbsp;MESSAGE ON CAKE
                              </small>
                            </button>
                          </p>
                        </router-link>
                        <router-link
                          v-else
                          v-bind:to="'/add-message/' + cart.indexOf(item)"
                        >
                          <p
                            class="flex-row space-between align-items-center"
                            style="padding:0px 10px;"
                          >
                            <span class="text-success">
                              <i class="icon-ok-circle"></i>
                            </span>
                            <span>
                              <small class="text-primary">
                                <i class="icon-edit-alt text-primary"></i>&nbsp;
                                EDIT
                              </small>
                            </span>
                          </p>
                        </router-link>
                      </template>
                      <template v-else>
                        <p
                          class="flex-row space-around align-items-center"
                          style="margin-right:15%;margin-left:15%;border-radius:10px;"
                        >
                          <span>
                            <button
                              @click="changeQty(item, 1)"
                              class="remove-btn-styles text-primary"
                            >
                              <i class="icon-plus" style="font-size:18px;"></i>
                            </button>
                          </span>
                          <strong>{{ item.qty }}</strong>
                          <span>
                            <button
                              @click="changeQty(item, 0)"
                              class="remove-btn-styles text-primary"
                            >
                              <i class="icon-minus" style="font-size:18px;"></i>
                            </button>
                          </span>
                        </p>
                      </template>

                      <!-- <small class="flex-row space-between bg-default" style="padding: 10px 20px;color:white;">
                                        <span>{{ item.weight }} </span>
                                        <span>&#8377; {{ item.price * item.qty }}</span>
                                    </small> -->
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="remove-scrollbar"
                style="background-color:#f5f6fa;"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="background: url('./../dist/party_bg_one.jpeg');background-size: cover;height: 100%;background-position: center;">    -->
      <div style="background-color:white;">
        <app-deals v-bind:cart="cart"></app-deals>
        <div style="height:100px;"></div>
      </div>
      <!-- <br> -->
      <nav class="nav-center">
        <div
          v-if="numItemsInCart() > 0 && !loading"
          class="nav-bottom flex-column bg-white space-between material-card bottom-nav"
        >
          <div class="flex-row center-spaced" style="margin-top: 5px;">
            <template v-if="cakePresentInCart">
              <span
                v-if="onlyRegularCakeInCart() && checkDeliveryTime(0, 23)"
                style="flex-basis:75%;"
              >
                Delivery - <strong>within 60 mins</strong>
              </span>
              <span v-else style="flex-basis:75%;"
                >Delivery-
                <strong
                  >{{ deliveryTime[0] }} ({{ deliveryTime[1] }} -
                  {{ deliveryTime[2] }})</strong
                >
                <br />
                <span
                  @click="showModal = true"
                  class="text-primary"
                  role="button"
                  style="cursor: pointer;line-height:24px;"
                >
                  <i class="icon-cw"></i>&nbsp;Reschedule</span
                >
              </span>
            </template>
            <span v-else
              >Delivery - <strong>Within 30 - 60 mins</strong>
              {{ removeDeliverTime }}
            </span>

            <router-link 
              style="flex-basis: 25%;"
              to="/delivery-address"              
              class="btn-wide text-white bg-success"            
              custom>
                <strong>NEXT</strong> &nbsp;<i class="icon-right"></i>                
            </router-link>
          </div>
        </div>
      </nav>
    </div>
    <div v-else class="card material-card">
      <div class="center" style="padding:20px 0;">
        <img src="./../assets/open-box.png" alt="" />
      </div>
      <center>
        IT'S EMPTY! <br />
        <small>BUY SOME STUFF AND COME BACK</small>
      </center>
      <br />
    </div>

    <modal v-if="showModal" @close="showModal = false">
      <template>
        <div slot="header">
          <h3>Change Time To</h3>
        </div>
        <div slot="body">
          <div v-if="checkDeliveryTime(0, 21) && !!dayAvailable.length && dayAvailable[0]">
            <p>Today | {{ today }}</p>
            <div
              v-if="checkDeliveryTime(0, 13)"
              @click="changeTime('Today', '10am', '1pm')"
              class="bg-default strong chips"
              role="button"
            >
              10am - 1pm
            </div>
            <div
              v-if="checkDeliveryTime(0, 16)"
              @click="changeTime('Today', '1pm', '4pm')"
              class="bg-default strong chips"
              role="button"
            >
              1pm - 4pm
            </div>
            <div
              v-if="checkDeliveryTime(0, 19)"
              @click="changeTime('Today', '4pm', '7pm')"
              class="bg-default strong chips"
              role="button"
            >
              4pm - 7pm
            </div>
            <div
              @click="changeTime('Today', '7pm', '10pm')"
              class="bg-default strong chips"
              role="button"
            >
              7pm - 10pm
            </div>
            <hr />
          </div>
          <div v-else>
            <em>No Slots Available Today</em>
            <hr />
          </div>
          <p>Tomorrow | {{ tomorrow }}</p>
          <div v-if="!!dayAvailable.length && dayAvailable[1]">
            <div
              @click="changeTime('Tomorrow', '10am', '1pm')"
              class="bg-default strong chips"
              role="button"
            >
              10am - 1pm
            </div>
            <div
              @click="changeTime('Tomorrow', '1pm', '4pm')"
              class="bg-default strong chips"
              role="button"
            >
              1pm - 4pm
            </div>
            <div
              @click="changeTime('Tomorrow', '4pm', '7pm')"
              class="bg-default strong chips"
              role="button"
            >
              4pm - 7pm
            </div>
            <div
              @click="changeTime('Tomorrow', '7pm', '10pm')"
              class="bg-default strong chips"
              role="button"
            >
              7pm - 10pm
            </div>
            <hr />
          </div>
          <div v-else>
             <em>No Slots available</em>
            <hr />
          </div>         
          <div v-if="!!dayAvailable.length && dayAvailable[2]">
          <p>{{ dayAfter }}</p>
          <div
            @click="changeTime(dayAfter, '10am', '1pm')"
            class="bg-default strong chips"
            role="button"
          >
            10am - 1pm
          </div>
          <div
            @click="changeTime(dayAfter, '1pm', '4pm')"
            class="bg-default strong chips"
            role="button"
          >
            1pm - 4pm
          </div>
          <div
            @click="changeTime(dayAfter, '4pm', '7pm')"
            class="bg-default strong chips"
            role="button"
          >
            4pm - 7pm
          </div>
          <div
            v-if="checkDeliveryTime(1, 22)"
            @click="changeTime(dayAfter, '7pm', '10pm')"
            class="bg-default strong chips"
            role="button"
          >
            7pm - 10pm
          </div>
          <div v-else>
            <em>No slots available</em>
          </div>              
          </div>
        </div>
        <div slot="footer">
          <small>
            Delivery will be made as early as possible under the chosen time slot unless instructed otherwise
          </small>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { bus } from "../main";
import cartMixin from "../cartMixin";
import itemsMixin from "./../mixins/itemsMixin"
import Deals from "./Offers";
import Modal from "./Modal";
import axios from 'axios';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  props: ["cart", "user", "loading"],
  data() {
    return {
      toggle: false,
      showModal: false,
      celebrationPackModal: false,
      deliveryTime: [],
      currentScroll: 0,
      dayAvailable: []
    };
  },
  async created() {
    let dayAvailable = await axios.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/dayAvailable.json')
    this.dayAvailable = dayAvailable.data
    if (this.checkDeliveryTime(0, 21) && dayAvailable.data[0]) {
      if (this.checkDeliveryTime(0, 13)) {        
        this.setDeliveryTime("Today", "10am", "1pm");
        console.log("this")  
      } else if (this.checkDeliveryTime(0, 16)) {
        this.setDeliveryTime("Today", "1pm", "4pm");
      } else if (this.checkDeliveryTime(0, 19)) {
        this.setDeliveryTime("Today", "4pm", "7pm");
      } else {
        this.setDeliveryTime("Today", "7pm", "10pm");
      }
    } else if (dayAvailable.data[1]) {    
      this.setDeliveryTime("Tomorrow", "10am", "1pm");
    } else if (dayAvailable.data[2]) {
      this.setDeliveryTime(this.dayAfter, "10am", "1pm");
    } else {
      alert("We are currently accepting website orders via menu.cherryfrost.in")
      window.location = 'https://menu.cherryfrost.in'
    }  

    this.saveDeliveryTimeInfo();
  },
  mounted() {
    setTimeout(() => {
      bus.$emit("show_loading", false);
    }, 1000);

    this.refreshCartItemsAvailability()
      .then(() => { console.log("all items available") })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        bus.$emit("show_loading", false);
      });
  },
  methods: {
    checkCartEmptyState() {
      setTimeout(() => {
        return this.isCartEmpty;
      }, 1000);
      return true;
    },
    scrollCartItemsDisplay(mode) {
      let el = this.$refs.cartEl;
      if (mode == 0) {
        this.currentScroll -= 40;
      } else {
        this.currentScroll += 40;
      }
      el.scrollLeft = this.currentScroll;
    },
    saveDeliveryTimeInfo() {
      // converting observable to array
      this.deliveryTime = JSON.parse(JSON.stringify(this.deliveryTime))            
      this.cart[0]["deliveryBy"] = [
        this.deliveryTime[0],
        this.deliveryTime[1],
        this.deliveryTime[2],
      ];
      bus.$emit("changeCart", this.cart);
    },
    changeToggleStatus() {
      this.toggle = !this.toggle;
      this.$refs.saveOrderOptions.disabled = !this.toggle;
    },
    changeTime(day, begin, end) {
      this.deliveryTime[0] = day;
      this.deliveryTime[1] = begin;
      this.deliveryTime[2] = end;
      this.showModal = false;
      this.saveDeliveryTimeInfo();
    },
    deleteItemFromCart(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    changeQty(item, mode) {
      mode == 1 ? (item.qty += 1) : (item.qty -= 1);
      if (item.qty == 0) {
        let item_index = this.cart.indexOf(item);
        this.cart.splice(item_index, 1);
      }
      this.$forceUpdate();
      bus.$emit("forceUpdate");
    },
    setDeliveryTime(day, begin, end) {
      this.deliveryTime[0] = day;
      this.deliveryTime[1] = begin;
      this.deliveryTime[2] = end;      
    },
  },
  computed: {
    removeDeliverTime() {
      this.cart[0]["deliveryBy"] = "ASAP";
      bus.$emit("changeCart", this.cart);
    },
    cakePresentInCart() {
      if (this.cart.filter((item) => item.subtype == "Bakery").length > 0)
        return true;
      else return false;
    },
    today() {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return fecha.format(new Date(), "ddd, MMM Do 'YY");
    },
    tomorrow() {
      let currentDate = new Date();
      let newDate = currentDate.addDays(1);
      return fecha.format(newDate, "ddd, MMM Do 'YY");
    },
    dayAfter() {
      let currentDate = new Date();
      let newDate = currentDate.addDays(2);
      return fecha.format(newDate, "ddd, MMM Do 'YY");
    },
    addonsPresent() {
      if (this.cart.find((item) => item.type == "addon")) {
        return true;
      }
      return false;
    },
    // numItemsInCart(): function() {
    //  return this.cart.filter(item => item.type == 'item').length;
    //       },
    cartLength() {
      return this.cart.length;
    },
  },
  mixins: [cartMixin, itemsMixin],
  components: {
    "app-deals": Deals,
    modal: Modal,
  },
};
</script>

<style scoped>
.cart-item-image {
  height: 80px;
  object-fit: cover;
  width: 100%;
  object-position: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: -10px;
  margin-top: 4px;
}

.flex-cart::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.flex-cart {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fill {
  display: flex;
}

.yellow {
  text-align: center;
  background-position: center;
  /* border-top:3px solid #e74c3c; */
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 14px;
}

.flex-cart {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0% 2%;
  padding-top: 4%;
  padding-bottom: 7%;
}

.flex-cart > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 180px;
}

.flex-cart > div:nth-child(n + 1) {
  margin: 0px 2%;
}

.flex-cart > div > div:first-child {
  display: flex;
}

#addons {
  background-color: #3498db;
  min-height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #addons>div {
        display: flex;
        justify-content: center;
    }

    #addons>div>div {
        text-align: center;
        flex-grow: 1;
        flex-basis: 30%;
        margin: 2%;
        color:white;
        font-weight: bold;
        background-color: #A8D0E6;
        padding: 2% 0% 4% 0%;        
        line-height: 20px;
    } */

h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-scrollbar {
  position: relative;
  height: 20px;
  background-color: white;
  top: -20px;
}

.success-btn-round {
  font-size: 12px;
  text-align: center;
  color: white;
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #00a651;
}

.chips {
  border-radius: 20px;
  padding: 10px;
  display: inline-block;
  margin: 5px;
}

.bottom-nav {
  padding: 10px;
  max-width: 768px;
}

.nav-center {
  margin: 0px auto;
  width: 768px;
}

@media (max-width: 786px) {
  .container {
    max-width: 100% !important;
    padding: 0;
  }

  .cart-item-control,
  .cart-item-control > div {
    display: none;
  }

  /* .bottom-nav{
            padding:10px;
            max-width: 768px;
            margin: 0px auto;
        } */
  /* .nav-center{
            margin: 0px auto; 
            width: 768px;
        } */
}
</style>
