<template>
  <div class="wrapper">
        <div class="container material-card">
          <div v-for="(address, index) in addresses" :key="`address-${index}`" class="row bg-white card-lite">
            <div class="col">
              <p class="flex-row align-items-center space-between">
               Guwahati - {{ address.pincode }}, Assam</span>                  
              </p>
              <p>{{ address.streetAddress }}, {{ address.delivery_location }} {{ address.phone }}</p>
              <p>
                <div class="flex-row space-between align-items-center">
                  <div>
                    <button @click="removeAddress(address.id, index)" class="remove-btn-styles bg-default text-dark" style="padding: 5px 10px;margin-right:10px;">
                        DELETE
                    </button>
                    <button @click="$router.push({ path: '/edit-delivery-address', query: { address: address.id }})" class="remove-btn-styles bg-default text-dark" style="padding: 5px 10px;">EDIT</button>
                  </div>
                  <button v-if="address.type == 1" class="remove-btn-styles text-grey" @click="$router.push('/change-delivery-address')">
                    <i class="icon-ok-circle text-success"></i>  &nbsp;CURRENT
                  </button>
                  <button v-else class="remove-btn-styles bg-default text-dark" style="padding: 5px 10px;" @click="selectDeliveryAddress(address.id)">
                    SELECT
                  </button>                                         
                </div>
              </p>
            </div>
          </div>
        </div>
  </div>
</template>


<script>

import {bus} from './../main'
import {db} from './../database'

export default {
  props: ['user'],
  data(){
    return {
      defaultAddress: '',
      addresses: [],
    }
  },
  created(){
    bus.$emit('show_loading', true);      
    db.collection("users").doc(this.user.uid).collection("addresses").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let address = doc.data()
        address['id'] = doc.id
        this.addresses.push(address)
        if(address.type == 1) {
          this.defaultAddress = address.id
        }
      })      
    }).then(()=>{        
        bus.$emit('show_loading', false)
    })
  },
  methods: {
    selectDeliveryAddress(addressKey){
      bus.$emit('show_loading', true)      
      let addressRef = db.collection("users/"+this.user.uid+"/addresses")      
      addressRef.doc(this.defaultAddress).update({ "type": 0 })      
      .then(()=> {
        addressRef.doc(addressKey).update({ "type": 1 })
      }).then(()=>{
        this.$router.go(-1)        
      })
    },
    removeAddress(addressKey, index) {
      bus.$emit('show_loading', true)            
      let addressRef = db.collection("users/"+this.user.uid+"/addresses")      
      
      addressRef.doc(addressKey).delete().then(()=> {
        if(addressKey == this.defaultAddress && this.addresses.length > 1) {
          this.addresses.splice(index, 1)          
          addressRef.doc(this.addresses[0].id).update({ "type": 1 })                
        }
        this.$router.go(-1);
      })
    }
  } 
}
</script>

