<!-- 2 database calls at created hook
1 database call for adding the order to orders collection
1 http call for getting an id (Can we do without it?)
 -->

<template>
  <div class="wrapper">
    <div class="container">
      <div class="row bg-white material-card">
        <div class="col-8">
          <p style="line-height: 20px;">
            {{ address.name }}, {{ address.streetAddress }},
            {{ address.delivery_location }}, {{ address.location }} -
            {{ address.pincode }}, {{ address.city }}, {{ address.phone }}
          </p>
          <p></p>
        </div>
        <div class="col-4">
          <div
            class="flex-column strong"
            style="font-size: small;line-height: 25px;border-left: 3px solid #4D4D4D;padding-left: 15px;color: #4D4D4D;"
          >
            <router-link
              v-bind:to="{
                path: '/edit-delivery-address',
                query: { address: addressKey },
              }"
              ><i class="icon-edit-alt"></i>&nbsp; Edit</router-link
            >
            <router-link to="/change-delivery-address"
              ><i class="icon-cw"></i>&nbsp; Change</router-link
            >
            <router-link to="/new-delivery-address"
              ><i class="icon-plus"></i>&nbsp; New</router-link
            >
          </div>
        </div>
        <div class="col-12">
          <notify-to
            v-if="addressLoaded"
            v-bind:user="user"
            v-bind:addressLoaded="addressLoaded"
            @getNotifyPhone="setNotifyPhone($event)"
          ></notify-to>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12" style="padding-bottom:0px;">
          <textarea
            v-model="instructions"
            rows="1"
            placeholder="Any special requests? (optional)"
          ></textarea>
        </div>
      </div>
    </div>
    <div
      class="container bg-white card-lite material-card"
      style="margin:10px auto;"
    >
      <br />
      <div class="flex-row align-items-center justify-content-center card-lite">
        <div class="center" style="padding-bottom: 5px;">
          <div style="letter-spacing: 1px;">
            <strong class="text-primary">|</strong> &nbsp; BILL DETAILS &nbsp;
            <strong class="text-primary">|</strong>
          </div>
        </div>
      </div>
      <div class="row align-items-center no-gutters" style="line-height: 25px;">
        <div
          class="col-7"
          style="padding-left:0;padding-bottom:0;padding-top:0;"
        >
          Cart Total
        </div>
        <div class="col-5 text-right flex-column">
          <span>&#8377;{{ getCost() }}</span>
          <small
            class="text-success"
            style="display:block;position:relative;bottom:10px;margin-bottom:-10px;"
            >( saved &#8377;{{
              getTotalNonDiscountedCost() - getCost()
            }}
            )</small
          >
        </div>
        <!-- <template v-if="bakeryItemInCart"> -->
        <template v-if="false">
          <div class="col-9" style="padding-bottom:0;">
            Regular Candles & Knife
            <small class="text-success">( INCLUDED )</small>
          </div>
          <div class="col-3 text-right" style="padding-bottom:0;">
            <del>&#8377;20</del> &nbsp; <span>&#8377;0</span>
          </div>
        </template>
        <div class="col-9" style="padding-bottom:0;">
          Delivery & Handling Charges (Estimated)
          <!-- <small class="text-success strong">
            ( {{ isDeliveryFree(address.isCustomLocality) ? '100% OFF' : '50% OFF' }} )
          </small> -->
        </div>
        <div class="col-3 text-right" style="padding-bottom:0;">          
          <span v-if="isDeliveryFree(address.isCustomLocality)"
            >
            <del>&#8377;60</del> &nbsp;
            <span>&#8377;0</span></span
          >
          <span v-else>
            <!-- <del>&#8377;60</del> &nbsp; -->
            <span>&#8377;{{ deliveryFee(address.isCustomLocality) }}</span></span
          >          
          <!-- <span v-else>&#8377;50</span> -->
        </div>
        <div class="col-12" style="border-bottom: 1px solid #e2e1e0;"></div>
        <div class="col-12 flex-row center-spaced">
          <strong>Grand Total</strong>
          <div>
            <strong>&#8377;{{ getTotalCost(address.isCustomLocality) }}</strong>
          </div>
        </div>
      </div>
    </div>

    <place-order
      v-if="addressLoaded && !otpVerified"
      v-bind:cart="cart"
      v-bind:user="user"
      v-bind:address="address"
      v-bind:addressLoaded="addressLoaded"
      @handlePlaceOrder="proceedToCheckout($event)"
    ></place-order>
    <p v-else-if="otpVerified" class="center" style="padding: 20px 0px;">
      PLACING ORDER NOW...
    </p>
    <modal :closeButton="false" v-if="showModal" @close="showModal = false">
      <div slot="header">
        <h3 v-if="otpRequestWaiting">
          Placing Your Order...<br />
          <small class="text-dark"><small>Please wait</small></small>
        </h3>
        <!-- <h3 v-else>Verify Order</h3> -->
        <h3 v-else>
          {{ modalMessage }} <br />
          <small class="text-dark"><small>Please wait</small></small>
        </h3>
      </div>
      <!-- <template v-if="otpRequestWaiting"> -->
      <template>
        <div slot="body" class="center">
          <pulse-loader :color="primary"></pulse-loader>
        </div>
      </template>
      <!-- <template v-else>
        <div slot="body">
          <p>OTP sent to {{ phone }} <br /></p>
          <input
            placeholder="Enter Otp (1234) Here "
            v-model.number="otp"
            type="tel"
            style="width:100%;"
          /> -->

      <!-- <small>
              <p>Taking longer than a minute? <span class="text-primary text-right"> Resend</span></p>          
            </small> -->
      <!-- </div>
        <div slot="footer">
          <button
            @click="handleOtpInput()"
            class="btn-wide bg-success text-white"
          >
            CONFIRM
          </button>
          <p>
            <small class="text-dark"
              >Waited longer than a minute? Close this and confirm again.</small
            >
          </p>
        </div>
      </template> -->
    </modal>
    <div style="height:150px;"></div>
  </div>
</template>

<script>
import { bus } from "./../main";
import { db } from "./../database";
import "./../useVueResource";
import cartMixin from "./../cartMixin";
import Modal from "./Modal.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DeliveryAddressNotifyTo from "./parts/DeliveryAddressNotifyTo";
import DeliveryAddressPlaceOrder from "./parts/DeliveryAddressPlaceOrder";
// import firebase from 'firebase';
// const RandomString = require('randomstring');

export default {
  metaInfo: {
    script: [
      {
        src: "https://checkout.razorpay.com/v1/checkout.js",
        async: true,
        defer: true,
      },
    ],
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (localStorage.getItem("cart") != null) {
  //       var cart = JSON.parse(localStorage.getItem("cart"));
  //       if (vm.isCartEmpty) {
  //         next("/menu/Bakery");
  //       } else next();
  //     }
  //   });
  // },
  props: ["cart", "user"],
  data() {
    return {
      addressLoaded: false,
      showAddressOptions: false,
      otpRequestWaiting: false,
      otpVerified: false,
      instructions: "",
      address: {},
      addressKey: "",
      ref: "",
      showPlaceOrder: true,
      location: "",
      orderPlaced: false,
      phone: "",
      picked: "One",
      displayBottomNav: false,
      primary: "#e74c3c",
      showModal: false,
      otp: "",
      paymentOrderId: null,
      modalMessage: "",
      person: {
        name: "Max",
        email: "max@email.com",
        contact: "9999999999",
      },
      errorData: "",
      currentOrder: {},
    };
  },
  created() {
    // firebase.functions().useFunctionsEmulator('http://localhost:5001')
    // var test = firebase
    //   .functions()
    //   .httpsCallable("test");

    // test()
    // .then(res => console.log(res))
    // .catch(err => console.log(err))

    bus.$emit("show_loading", true);
    this.$on("close", () => {
      this.showModal = false;
    });
    // get the default address
    let addRef = db
      .collection("users/" + this.user.uid + "/addresses")
      .where("type", "==", 1);

    addRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size == 0) {
          this.$router.replace("new-delivery-address?firstAddress=true");
        } else {
          querySnapshot.forEach((doc) => {
            this.address = doc.data();
            this.addressKey = doc.id;
          });
          this.addressLoaded = true;
          bus.$emit("show_loading", false);
          this.displayBottomNav = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    setNotifyPhone(phone) {
      this.phone = phone;
    },
    sendOtp() {
      // var ref = this
      // var generateOtp = firebase.functions().httpsCallable('generateOtp');
      // generateOtp({phone:this.phone})
      // .then(function(result) {
      //     ref.otpRequestWaiting = false
      // }).catch(function(err){
      //     console.log(err)
      // })
      setTimeout(() => {
        this.otpRequestWaiting = false;
      }, 1000);
    },
    async proceedToCheckout(picked) {
      // alert("Payment Gateway Integration in progress. Check back later")
      // return

      console.log("woo")
      this.showModal = true;
      // this.handleOtpInput()
      // this.modalMessage = "Initializing payment ...";

      this.modalMessage = "Confirming Items ...";

      try {
        let data = await this.createNewPayment();

        this.paymentOrderId = data.id;

        // this.modalMessage = "Opening payment options ...";

        this.otpRequestWaiting = true;        
        
        let res = await this.confirmOtp();
        
        console.log("order set")        

        this.$nextTick(() => {
          this.showModal = false;
          console.log(res.data.id)          
          this.$router.replace({ path: "/checkout", query: { oid: res.data.id }});          
        })

        // await this.payOnline(data);

        // this.otpRequestWaiting = true;

        // let verifyRes = await this.verifyPayment();
      
        // console.log(verifyRes)

        // if (verifyRes.data.success == true) {
        //   this.showModal = false;
        //   this.$router.replace("/checkout");
        // }


      } catch (error) {
        this.errorData = !!error.error
          ? error.error.description
          : "Order not placed. Try again";
      }

      return;
    },
    handleOtpInput() {
      this.otpRequestWaiting = true;
      this.confirmOtp()
        .then((res) => {
          // this.showModal = false;
          // this.otpRequestWaiting = false;
          // this.otpVerified = true;

          return this.payOnline(res.oid);

          // this.$router.replace("/checkout");
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.otpRequestWaiting = false;
          this.otp = "";
          this.$toasted.error(err);
          console.log(err);
        });
    },
    createNewPayment() {
      return new Promise(async (resolve, reject) => {
        var data;
        // firebase.functions().useFunctionsEmulator('http://localhost:5001')
        var makeOnlinePayment = firebase
          .app()
          .functions("asia-east2")
          // .functions()
          .httpsCallable("makeOnlinePayment");

        try {
          var res = await makeOnlinePayment({ amount: this.getTotalCost(this.address.isCustomLocality) });
          data = res.data;
        } catch (error) {
          reject(error);
        }

        if (!data) {
          reject("no data");
        }

        resolve(data);
      });
    },
    payOnline(data) {
      return new Promise(async (resolve, reject) => {
        var thisRef = this;

        var options = {
          // key: "rzp_test_WYkPpCaD6AoGow",
          key: "rzp_live_tEPSCLCFzyEwXS",
          currency: data.currency,
          amount: data.amount.toString(),
          order_id: data.id,
          name: "Complete Payment",
          description: `${this.numItemsInCart() } items`,
          image:
            "https://storage.googleapis.com/cherryfrost_master/logo_192x192.png",
          modal: {
            ondismiss: function() {
              thisRef.showModal = false;
              thisRef.$nextTick(() => {
                thisRef.$toasted.error(thisRef.errorData);
              });
            },
          },
          config: {
            display: {
              blocks: {
                upi: {
                  name: "UPI apps or id",
                  instruments: [
                    {
                      method: "upi",
                      // apps: ["google_pay", "phonepe"]
                    },
                  ],
                },
                other: {
                  //  name for other block
                  name: "Credit Card, Debit Card",
                  instruments: [
                    {
                      method: "card",
                    },
                  ],
                },
                // wallet: {
                //   name: "Wallet",
                //   instruments: [
                //     {
                //       method: "wallet",
                //     },
                //   ],
                // },
              },
              hide: [
                {
                  method: "netbanking",
                },
              ],
              // sequence: ["block.hdfc", "block.other", "block.wallet"],
              sequence: ["block.upi", "block.other"],
              preferences: {
                show_default_blocks: false, // Should Checkout show its default blocks?
              },
            },
          },
          handler: (res) => {
            // this.paymentComplete = true
            resolve(res);
          },
          prefill: {
            name: this.user.name,
            email: this.user.email,
            contact: this.phone,
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on("payment.failed", function(response) {
          thisRef.showModal = false;
          reject(response);
        });

        paymentObject.open();
      });
    },
    confirmOtp() {
      // define the order
      let date = fecha.format(new Date(), "ddd, MMM Do 'YY - hh:mm a");
      var ref = this;
      // DONOT TAKE THE USER TOKEN INFO FROM THE CLIENT. IT IS SENSITIVE
      var items = this.cart.filter((item, index) => index > 0);

      // let order = {
      //   items: items,
      //   address: this.address,
      //   total: this.getTotalCost(),
      //   orderStatus: "PROCESSING",
      //   paymentStatus: "UNPAID",
      //   orderedOn: date,
      //   notifyTo: this.phone,
      //   orderTime: Date.now(),
      //   meta: this.cart[0],
      //   paymentOrderId: this.paymentOrderId,
      //   instructions: this.instructions,
      // };

      let order = {
        items: items,
        address: this.address,
        total: this.getTotalCost(this.address.isCustomLocality),
        orderStatus: "PROCESSING",
        paymentStatus: "UNPAID",
        orderedOn: date,
        notifyTo: this.phone,
        orderTime: Date.now(),
        meta: this.cart[0],
        paymentOrderId: this.paymentOrderId,
        instructions: this.instructions,
        statusMessage: ""
      };

      // console.log(order)
      // return Promise.resolve()

      var verifyOtp = firebase
        .app()
        .functions("asia-east2")
        .httpsCallable("createNewOrder");
      // firebase.functions().useFunctionsEmulator('http://localhost:5001')
      // var verifyOtp = firebase.functions().httpsCallable("createNewOrder");
      return verifyOtp({ otp: ref.otp, order: order });
    },
    verifyPayment() {
      // firebase.functions().useFunctionsEmulator('http://localhost:5001')
      var verifyPayment = firebase
        .app()
        .functions("asia-east2")
        .httpsCallable("verifyPayment");
      // var verifyPayment = firebase.functions().httpsCallable("verifyPayment");

      return verifyPayment({ paymentOrderId: this.paymentOrderId });
    },
  },
  mixins: [cartMixin],
  components: {
    "pulse-loader": PulseLoader,
    modal: Modal,
    "place-order": DeliveryAddressPlaceOrder,
    "notify-to": DeliveryAddressNotifyTo,
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  border: 1px solid #bdbdbd;
  padding: 10px;
  box-sizing: border-box;
}

textarea:focus {
  outline: 0;
}
</style>