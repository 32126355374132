<template>
  <div class="bottom-nav-center">
    <div
      v-if="orderInfoLoaded"
      class="nav-bottom container bg-white animated fadeInUp material-card delivery-bottom-nav"
    >
      <template v-if="showPlaceOrder">
      <!-- <template v-if="true"> -->
        <!-- <div class="flex-row" style="margin-top:10px;margin-bottom:10px;">
          <div>
            <input type="radio" id="two" value="Two" v-model="picked" />
            <label style="margin-right:20px;" for="two">Pay Online</label>
          </div>
          <div>
            <input disabled type="radio" id="one" value="One" v-model="picked" />
            <label style="color: grey;" for="one">COD (Coming Soon)</label>
          </div>
        </div> -->
        <div class="row no-gutters">
          <div class="col-6">
            <button
              class="remove-btn-styles btn-wide bg-default text-dark"
              @click="showReviewItemsModal = true"
            >
              REVIEW ORDER &nbsp;<i class="icon-up-open"></i>
            </button>
          </div>
          <div class="col-6">
            <button
              class="remove-btn-styles btn-wide bg-success text-white"
              @click="verifyAndPlaceOrder"
            >
              CONFIRM &nbsp;<i class="icon-right"></i>
            </button>
          </div>
        </div>
      </template>
      <!-- <div v-else-if="true" class="row no-gutters">
        <div class="col-12">
          <div class="center">
            <p>
              <small>
                Sorry! The selected dates are unavailable currently
                <br />
                Please call/whatsapp us if you need it on or after <strong>3rd May</strong>
              </small>
            </p>
            <div>
              <a
                href="tel:7578025236"
                data-rel="external"
                class="btn-wide bg-success text-white margin-top-5"
                style="display:inline-block;"
              >
                CALL US NOW
              </a>
            </div>
          </div>
        </div>
      </div>       -->
      <div v-else class="row no-gutters">
        <div class="col-12">
          <div class="center">
            <p>
              <small>
                Sorry! We currently only accept one active order per user :(
                <br />
                However, you always place an order by call!
              </small>
            </p>
            <div>
              <a
                href="tel:7578025236"
                data-rel="external"
                class="btn-wide bg-success text-white margin-top-5"
                style="display:inline-block;"
              >
                CALL US NOW
              </a>
            </div>
            <!-- <button disabled class="remove-btn-styles btn-wide" style="background-color:#d3d3d3;color:#939393;">
				      PLACE ORDER
				    </button> -->
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex-row justify-content-center"
      style="height: 50px;align-items: flex-end;"
    >
      <pulse-loader></pulse-loader>
    </div>
    <modal @close="showReviewItemsModal = false" v-if="showReviewItemsModal">
      <div slot="header">
        <h3>Cart Summary</h3>
      </div>
      <div slot="body">
        <div
          :key="`summary-${index}`"
          class="flex-row center-spaced bg-default"
          v-for="(item, index) in getCartItems"
          style="margin-bottom: 10px;padding: 10px;border-radius: 5px; "
        >
          <span style="flex-basis: 20%; padding-right: 10px;">
            <img
              :src="item.url"
              style="width:100%; object-fit: contain;border-radius: 12px;"
              alt=""
            />
          </span>
          <span style="flex-basis: 65%;">
            {{ item.name }} ( {{ item.flavour }}, {{ item.weight }} ) x
            {{ item.qty }}
          </span>
          <span style="flex-basis: 15%;" class="text-right">
            &#8377;{{ item.price }}
          </span>
        </div>
      </div>
      <div slot="footer">
        <div style="margin-bottom:20px;">
          <em>
            Delivery by {{ cart[0].deliveryBy[0] }},
            {{ cart[0].deliveryBy[1] }} - {{ cart[0].deliveryBy[2] }}
          </em>
        </div>
        <div class="text-right text-primary">
          <i class="icon-ok-circle"></i> &nbsp;Ready To Place
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { db } from "./../../database";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";
import Modal from "./../Modal";
import cartMixin from "./../../cartMixin";
import itemsMixin from "./../../mixins/itemsMixin"
import axios from 'axios';

export default {
  props: ["address", "addressLoaded", "user", "cart"],
  data() {
    return {
      showReviewItemsModal: false,
      picked: "Two",
      showPlaceOrder: true,
      orderInfoLoaded: false,
    };
  },
  created() {
    const ordersRef = db.doc("placedOrders/" + this.user.uid);
    ordersRef.get().then((doc) => {
      if (doc.exists) {
        if (
          doc.data()["orderStatus"] != "FAILED" &&
          doc.data()["isVerified"] == true
        ) {
          this.showPlaceOrder = false;
          // console.log("previous order already exists")
        }
      }
      this.setOrderInfoLoaded();
    });
  },
  methods: {
    setOrderInfoLoaded() {
      this.orderInfoLoaded = true;
    },
    async verifyAndPlaceOrder() {
      let storeClosed = await axios.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/storeClosed.json')            

      if(storeClosed.data)  {
        this.$toasted.show("Sorry! We're closed for now", { duration: 2500 });	        
        this.$router.replace('/')
        return
      }

      let allItemsAvailable = await this.refreshCartItemsAvailability()

      if(!allItemsAvailable) {
        this.$toasted.show("Some or all added items are unavailable", { duration: 2500 });	        
        console.log(this.getCartItems)
        this.$router.replace('/cart')
        return
      }      

      if(await this.isOrderTimeValid()) {
        // console.log("valid")
        // return
        this.$emit('handlePlaceOrder', this.picked)
      } else {
        this.$toasted.show("Selected time slot not available.", { duration: 2500 });
        this.$router.replace('/cart')
      }

      return
    },
    async isOrderTimeValid() {
      let time = this.cart[0].deliveryBy;            

      let dayAvailable = await axios.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/dayAvailable.json')

      if(time[0] == "Today" && dayAvailable.data[0] && this.checkDeliveryTime(0,21)) {        
       return true
      }

      else if (time[0] == "Tomorrow" && dayAvailable.data[1]) {        
       return true
      }

      else if (dayAvailable.data[2] && !["Today", "Tomorrow"].includes(time[0])) {     
       return true
      }

      else {
        return false
      }
      
    },
  },
  components: {
    "pulse-loader": BeatLoader,
    modal: Modal,
  },
  mixins: [cartMixin, itemsMixin],
};
</script>

<style scoped>
.delivery-bottom-nav {
  max-width: 768px;
}

@media (min-width: 700px) {
  /* .delivery-bottom-nav{
		max-width: 100%;
	} */

  .bottom-nav-center {
    margin: 0px auto;
    width: 768px;
  }
}
</style>
