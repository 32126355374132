<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="material-card" style="position:relative;top:-25px;">
            <div id="element"></div>
            <div class="container-fluid bg-white">
              <div class="row no-gutters">
                <div class="col">
                  <div class="flex-row center-spaced">
                    <div>
                      <!-- <span class="cursor-pointer" @click="changeSideType('MealSides')" style="display: inline-block;padding:5px 0px;" :style="{'border-bottom': currentSubtype=='MealSides'?'3px solid #e74c3c':'unset' }" role="button">Meal Sides</span> &nbsp;  -->
                      <span
                        class="cursor-pointer"
                        @click="changeSideType('side')"
                        style="display: inline-block;padding:5px 0px;"
                        :style="{
                          'border-bottom':
                            currentSubtype == 'side'
                              ? '3px solid #e74c3c'
                              : 'unset',
                        }"
                        role="button"
                        >Party Sides & Snacks</span
                      >
                    </div>
                    <!-- <span
                      v-scroll-to="{ el: '#element', offset: -65 }"
                      role="button"
                      ><i class="icon-up-open"></i
                    ></span> -->
                  </div>
                </div>
              </div>
              <!-- <div class="flex-row dead-center">
                <div class="row">
                  <div class="col-12" style="margin-bottom:20px;">
                    <em>Coming Soon...</em>
                  </div>
                </div>
             </div>               -->
              <div v-if="sides.length == 0" class="flex-row dead-center">
                <div class="row">
                  <div class="col-12">
                    <beat-loader></beat-loader>
                  </div>
                </div>
              </div>
              <div v-else class="row no-gutters">
                <template v-for="(sideCategory, index) in sideCategories">
                  <transition
                    v-bind:key="sideCategory"
                    enter-active-class="animated fadeIn faster"
                    leave-active-class="animated fadeOut faster"
                  >
                    <div
                      class="col-6 card-lite"
                      style="display: flex;flex-direction: column;justify-content: space-between;flex-wrap: wrap;"
                    >
                      <div
                        style="border:1px solid #E5E5E5;border-radius: 8px;padding-bottom:10px;"
                      >
                        <div
                          @click="openCategoryItems(sideCategory)"
                          class="flex-row dead-center cursor-pointer bg-success-light"
                          v-bind:style="{
                            'background-image':
                              'url(' + sideCategoryImages[index] + ')',
                          }"
                          style="height:70px;background-size:cover;background-position:center;border-radius: 8px;"
                          role="button"
                        ></div>

                        <div style="padding:0 5px;">
                          <div
                            class="text-black strong margin-top-10"
                            style="font-size:12px;padding-left:5px;"
                          >
                            {{ sideCategory.toUpperCase() }}
                          </div>
                          <div
                            style="color:grey;font-size:10px;letter-spacing:1px;padding-left:5px;margin-top:3px;"
                          >
                            STARTS AT &#8377;{{
                              minCategoryItemPrice(sideCategory)
                            }}
                          </div>
                          <div class="text-center" style="margin-top:10px;">
                            <button
                              @click="openCategoryItems(sideCategory)"
                              class="remove-btn-styles bg-default btn-wide text-dark"
                              style="padding-top:8px;padding-bottom:8px;"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </div>
            </div>
            <modal v-if="showAddonModal" @close="showAddonModal = false">
              <div slot="header">
                <h3 class="heading-text" style="margin-bottom:5px;">
                  {{ currentCategoryName }}
                </h3>
                <small>{{ currentCategoryItems.length }} ITEMS</small>
              </div>
              <div slot="body">
                <template v-for="(item, index) in currentCategoryItems">
                  <div v-bind:key="index" class="row">
                    <div class="col-4 flex-row dead-center">
                      <img
                        style="max-height:100px;"
                        v-bind:src="item.url"
                        alt=""
                      />
                    </div>
                    <div class="col-8">
                      <div>
                        {{ item.name }}<small> ({{ item.flavour }})</small>
                      </div>
                      <div class="flex-row">
                        <template v-for="(name, index) in item.variant.type">
                          <small
                            v-bind:key="index"
                            v-if="
                              !variantInCart(item.id, item.variant.type[index])
                            "
                            @click="changeSelectedItemVarient(item.id, index)"
                            class="material-card strong margin-top-10 cursor-pointer"
                            style="padding: 5px;border-radius: 3px;display:inline-block;"
                            v-bind:class="{
                              'bg-success text-white':
                                currentCategoryVarients[item.id] == index,
                              'bg-default text-dark':
                                currentCategoryVarients[item.id] != index,
                            }"
                            >{{ name }}</small
                          >
                          <small
                            v-bind:key="index"
                            @click="showItemStatusInfo()"
                            v-else
                            class="text-dark margin-top-10 cursor-pointer"
                            style="padding: 5px;border-radius: 3px;display:inline-block;"
                            ><i class="icon-ok-circle"></i> {{ name }}</small
                          >
                          &nbsp;&nbsp;
                        </template>
                      </div>
                      <div
                        v-if="
                          !allVarientsInCart(item.id, item.variant.price.length)
                        "
                        class="flex-row center-spaced"
                        style="margin-top:15px;"
                      >
                        <div style="font-size:18px;">
                          &#8377;{{
                            item.variant.price[currentCategoryVarients[item.id]]
                          }}
                        </div>
                        <button
                          v-if="item.available"
                          style="flex-basis:70%;border:2px solid #f3f3f3;"
                          @click="addSideToCart(item)"
                          class="btn-wide bg-white text-primary"
                        >
                          <i class="icon-plus"></i> &nbsp;ADD
                        </button>
                        <span v-else>
                          <em style="color:#2b2b2b;">Out of stock</em>
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr
                    v-if="index < currentCategoryItems.length - 1"
                    style="margin: 15px 0px;"
                  />
                </template>
              </div>
              <div slot="footer"></div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "./../main";
import { db } from "./../database";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";
import Modal from "./Modal.vue";

export default {
  props: ["cart"],
  data() {
    return {
      sides: [],
      prevCategory: "none",
      showAddonModal: false,
      sideCategories: [],
      currentCategoryItems: [],
      currentCategoryName: "",
      selectedVarient: "",
      currentCategoryVarients: {},
      imageUrl: "",
      sideCategoryImages: [],
      currentSubtype: "",
    };
  },
  created() {
    bus.$emit("show_loading", false);
    // this.bakeryItemInCart? this.currentSubtype = "side": this.currentSubtype = "MealSides"
    this.currentSubtype = "side";
    this.getSidesFromServer(this.currentSubtype);
    bus.$on("hideModal", () => {
      this.showAddonModal = false;
    });
  },
  components: {
    "beat-loader": BeatLoader,
    modal: Modal,
  },
  methods: {
    changeSideType(subtype) {
      this.currentSubtype = subtype;
      this.sideCategories.length = 0;
      this.sideCategoryImages.length = 0;
      this.$nextTick(() => {
        this.getSidesFromServer(subtype);
      });
      // setTimeout(, 1000, subtype)
    },
    getSidesFromServer(subtype) {
      const itemsRef = db.collection("products");
      itemsRef
        .where("subtype", "==", subtype)
        .orderBy("category")
        .onSnapshot((querySnapshot) => {
          this.sides.length = 0;
          querySnapshot.forEach((doc) => {
            this.sides.push(doc.data());
            this.addCategory(doc.data().category);
          });
          this.setCategoryBackgroundImages();
        });
    },
    setCategoryBackgroundImages() {
      // let thisRef = this
      // this.$http.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/images.json')
      // .then(response => {
      // 	return response.json()
      // })
      // .then(data => {
      this.sideCategories.forEach((sideCategory) => {
        this.sideCategoryImages.push(
          `https://storage.googleapis.com/cherryfrost_master/${encodeURI(
            sideCategory
          )}.jpg`
        );
      });
      // })
    },
    showItemStatusInfo() {
      this.$toasted.show(
        "Item Already Added. Check Cart<i class='icon-attention text-default'></i>"
      );
    },
    minCategoryItemPrice(sideCategory) {
      let categoryItems = this.sides.filter(
        (side) => side.category == sideCategory
      );
      let prices = [];
      categoryItems.forEach((item) => {
        prices.push(item.variant.price[0]);
      });
      return Math.min(...prices);
    },
    changeSelectedItemVarient(itemId, index) {
      this.currentCategoryVarients[itemId] = index;
      this.$forceUpdate();
    },
    openCategoryItems(sideCategory) {
      this.currentCategoryName = sideCategory;
      this.currentCategoryItems = this.sides.filter(
        (side) => side.category == sideCategory
      );
      this.showAddonModal = true;
      this.currentCategoryVarients = {};
      this.currentCategoryItems.forEach((item) => {
        // this.currentCategoryVarients[item.id] = 0
        this.setDefaultSelectedItemVarient(item.id, item.variant.type);
      });
    },
    addCategory(category) {
      if (!this.sideCategories.includes(category)) {
        this.sideCategories.push(category);
      }
    },
    variantInCart(itemId, variantWeight) {
      let sideVarientQty = Object.keys(
        this.cart.filter(
          (item) => item.id == itemId && item.weight == variantWeight
        )
      ).length;
      if (sideVarientQty > 0) return true;
      else return false;
    },
    sideInCart(side) {
      let sideItemQty = Object.keys(
        this.cart.filter((item) => item.id == side.id)
      ).length;
      if (sideItemQty > 0) return true;
      else return false;
    },
    addSideToCart(item) {
      // if(this.sideInCart(side)) {
      // 	return
      // }
      let itemToBeAdded = Object.assign({}, item);
      let sideIndex = this.currentCategoryVarients[item.id];
      itemToBeAdded["price"] = item.variant.price[sideIndex];
      itemToBeAdded["weight"] = item.variant.type[sideIndex];
      delete itemToBeAdded.variant;
      itemToBeAdded["qty"] = 1;
      this.cart.push(itemToBeAdded);
      this.setDefaultSelectedItemVarient(item.id, item.variant.type);
      this.showModal = false;
      this.$toasted.show(
        "Done! Added To Cart<i class='icon-ok-circle text-success'></i>"
      );
    },
    allVarientsInCart(itemId, numVarients) {
      let numVarientsInCart = Object.keys(
        this.cart.filter((item) => item.id == itemId)
      ).length;
      if (numVarientsInCart == numVarients) {
        return true;
      } else {
        return false;
      }
    },
    setDefaultSelectedItemVarient(itemId, itemVarients) {
      itemVarients.every((variant, index) => {
        if (!this.variantInCart(itemId, variant)) {
          this.currentCategoryVarients[itemId] = index;
          return false;
        } else return true;
      });
      this.$forceUpdate();
    },
  },
};
</script>
