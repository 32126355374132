import { db } from './../database'

export default {
  methods: {
    async refreshCartItemsAvailability() {
        let itemData;	
    
        for(const item of this.getCartItems) {
          try {						
            let snap = await db.doc("products/"+item.id).get()				  
            itemData = snap.data()
            if (!itemData.available) {
              let index = this.cart.findIndex((x) => x.id == item.id);
              this.itemsUnavailable.push(item.name);
              this.cart.splice(index, 1);
            }
          } catch (error) {
            return Promise.reject(error)	
          }						
        }
    
        if(this.itemsUnavailable.length) {					
          return Promise.resolve(false)				
        }					
    
        return Promise.resolve(true)
      },	
  }
}