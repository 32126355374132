<template>
  <div class="wrapper">
    <div class="container">
      <!-- {{ orders }} -->
      <div
        v-for="(order, index) in orders" :key="order.oid+'-'+index"
        class="row bg-white"
        style="border-top: 5px solid #f5f6fa"
      >
        <div class="col">
          <p class="flex-row align-items-center space-between">
            <span>
              <strong>ORDER #{{ order.oid }}</strong> <br />
              <small class="grey">Placed on {{ order.orderedOn }} </small>
            </span>
          </p>
          <div>
            <p>
              Delivered successfully on {{ order.orderedOn }}
            </p>
            <p class="flex-row align-items-center">
              <!-- Rated {{ !!order.rating? order.rating.rating+' stars' : '****' }}  -->
              <span style="padding-right:10px;">Rated </span>              
              <star-rating :inline="true" active-color="#00A651" v-if="!!order.rating" :read-only="true" :star-size="20" :rating="order.rating.rating"></star-rating>
              <em v-else>Not rated</em>
            </p>
          </div>
          <!-- <div class="flex-row align-items-center space-between">
            <div style="flex-basis:80%">
              <span v-for="(item, index) in order.items" :key="item.id">
                <span v-if="index != 0">, </span> {{ item.name }} ({{
                  item.weight
                }}) x {{ item.qty }}
              </span>
            </div>
            <div style="flex-basis:20%;text-align:right;">
              <strong>&#8377; {{ order.total }}</strong>
            </div>
          </div> -->
          <div class="row no-gutters" style="margin-top:10px;">
            <div class="col-6 center" style="padding:7.5px;">
              <button class="btn-wide text-white bg-primary card-lite"  @click="showOrderDetails(index)">
                Order Details
              </button>
            </div>
            <div class="col-6 center" style="padding:7.5px;">
              <button
                class="btn-wide text-white bg-primary card-lite bg-dark"
                @click="fetchTransactionDetails(order.paymentOrderId, index)"
              >
                Receipt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ORDER MODAL -->

      <modal v-if="showOrderModal" @close="showOrderModal = false">
       
          <div slot="header">
            <h3>Order # {{ orders[currentIndex].oid }}</h3>              
          </div>
          <div slot="body">
              <div class="flex-row center-spaced bg-default"  v-for="(item,index) in orders[currentIndex].items" v-bind:key="index" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
                   <span style="flex-basis: 20%; padding-right: 10px;">
                    <img :src="item.url" style="width:100%; max-height:50px;object-fit: contain;border-radius: 12px;" alt="">
                  </span>
                  <span style="flex-basis: 65%;"> {{ item.name }} ({{ item.flavour }}, {{ item.weight }} ) x {{ item.qty }} </span>  
                  <span style="flex-basis: 15%;" class="text-right">  &#8377;{{ item.price }} </span>
              </div>        
          </div>
          <div slot="footer">
            
            <!-- <button
              @click="reorder(order.items)"
              class="btn-wide text-white card-lite bg-success"
            >
              REORDER
            </button>             -->
          </div>
           
        </modal> 

    <!-- MODAL -->
    <modal v-if="transactionModal" @close="transactionModal = false">
      <div slot="header">
        <h3 style="margin-bottom: 5px">Order #{{ orders[currentIndex].oid }}</h3>
        <!-- <span> {{ order.orderedOn }}</span>   -->
      </div>
      <div slot="body">
      <template v-if="!!transactionDetails.paymentId">
        <div
          class="flex-row center-spaced bg-default"
          style="margin-bottom: 10px;padding: 10px;border-radius: 5px;"
        >
          <span style="flex-basis: 80%;"> Transaction Id: </span>
          <span style="flex-basis: 20%;" class="text-right">
            {{ transactionDetails.paymentId }}
          </span>
        </div>
        <div
          class="flex-row center-spaced bg-default"
          style="margin-bottom: 10px;padding: 10px;border-radius: 5px;"
        >
          <span style="flex-basis: 80%;"> Transaction Method </span>
          <span style="flex-basis: 20%;" class="text-right">
            {{ transactionDetails.method.toUpperCase() }}
          </span>
        </div>
      </template>
        <div
          class="flex-row center-spaced bg-default"
          style="margin-bottom: 10px;padding: 10px;border-radius: 5px;"
        >
          <span style="flex-basis: 30%;"> Delivery Address </span>
          <span style="flex-basis: 60%;" class="text-right">
            {{ orders[currentIndex].address.streetAddress }},
            {{ orders[currentIndex].address.location }},{{
              orders[currentIndex].address.pincode
            }}, {{ orders[currentIndex].address.city }}
          </span>
        </div>
        <div
          class="flex-row center-spaced bg-default"
          style="margin-bottom: 10px;padding: 10px;border-radius: 5px;"
        >
          <span style="flex-basis: 30%;"> Phone Number </span>
          <span style="flex-basis: 60%;" class="text-right">
            {{ orders[currentIndex].notifyTo }}
          </span>
        </div>
      </div>
      <div class="text-right" slot="footer" style="margin-top-10">
        <!-- <small>{{ orders[currentIndex].orderedOn }}</small> -->
        <span class="icon-price text-dark">Total </span> &#8377;
        {{ orders[currentIndex].total }}
      </div>
    </modal>
  </div>
</template>

<script>
import { bus } from "./../main";
import { db } from "./../database";
import cartMixin from "./../cartMixin";
import Modal from "./Modal.vue";
import StarRating from 'vue-star-rating'

export default {
  props: ["user", "cart"],
  data() {
    return {
      transactionDetails: null,
      transactionModal: false,
      showOrderModal: false,
      currentIndex: null,
      orders: [],
    };
  },

  components: {
    modal: Modal,
    StarRating
  },
  created() {
    bus.$emit("show_loading", true);
    let ordersRef = db
      .collection("completedOrders")
      .where("uid", "==", this.user.uid)
      .orderBy("orderTime", "desc")      

    ordersRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc, index) => {
        this.orders.push(doc.data());
      });
      bus.$emit("show_loading", false);
    });
  },
  methods: {
    fetchTransactionDetails(paymentId, index) {
      
      let TransRef = db.doc(
        "users/" + this.user.uid + "/payments/" + paymentId
      );
      TransRef.get().then((snap) => {
        this.transactionDetails = snap.data();
        this.currentIndex = index;

        if (this.transactionDetails) {
          this.transactionModal = true;
        }
      });
    },

    showOrderDetails(index){
        this.currentIndex = index;
        this.showOrderModal = true
    },
    reorder(orderItems) {
      this.emptyCart();
      let items = this.getItemsBasicInfo(orderItems);
      this.orderFromSavedMeal(items);
    },
    orderFromSavedMeal(items) {
      this.addSavedItemsToCart(items).then(() => {
        this.$router.push("/cart");
      });
    },
    addSavedItemsToCart(items) {
      // this.savedOrdersLoading = true
      bus.$emit("show_loading", true);
      const thisRef = this;
      return new Promise((resolve, reject) => {
        items.forEach((item, index) => {
          let currentItem = {};
          db.collection("products")
            .where("id", "==", item.id)
            .get()
            .then((data) => {
              data.forEach((item) => (currentItem = item.data()));

              if (currentItem.variant) {
                let itemVarients = currentItem.variant.type;
                let itemVarientTypeIndex = itemVarients.indexOf(item.variant);
                currentItem["weight"] = item.variant;
                currentItem["qty"] = item.qty;
                currentItem["price"] = this.getCurrentItemPrice(
                  currentItem,
                  itemVarientTypeIndex,
                  item.veg
                );
                delete currentItem["variant"];
              }
              thisRef.cart.push(currentItem);
              if (index == items.length - 1) {
                resolve();
              }
            });
        });
      });
    },
  },
  mixins: [cartMixin],
};
</script>

1
