<template>
  <div class="bg-default">
      <div class="container">
          <div class="row card">
              <div class="col">
                <template v-if="category == 'Tiffin Cake'">
                <h4>Sticky note message</h4>
                    <textarea v-model="message" rows="2" placeholder="Eg- Happy Birthday Jim-Jam!">          
                    </textarea>                       
                </template>
                <template v-else>
                    <h4>Message on Cake</h4>
                    <textarea v-model="message" rows="2" placeholder="Eg- Happy Birthday Jim-Jam!">          
                    </textarea>                                       
                </template>
                <div class="flex-row center-spaced margin-top-10">
                    <div style="flex-basis:70%;">
                        <span style="line-height:30px;">Occasion: </span>
                        <select v-model="occasion">
                            <option value="Birthday">Birthday</option>
                            <option value="Anniversary">Anniversary</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div class="strong" v-bind:class="{red:message_error}">{{ messageLength }}/ {{ maxLength }}</div>
                </div>
                <p>
                    <button class="btn-message" v-on:click="addMessageToItem(cart[pos])">DONE</button>
                </p>
              </div>
                <div v-show="category == 'Tiffin Cake'" class="container" style="background-color: #f5f6fa;">
                    <div class="row">
                        <div class="col grey">
                            <p>
                                <strong>Please Note</strong>
                            </p>
                            <p> <small>
                                Only for Tiffin Cakes:- The message would be written on a note on the inside of the box. If you want to write something on the cake as well, please mention it when we ask for optional instructions.
                            </small> </p>                            
                            <p> <small>                                    
                            </small> </p>                    
                        </div>
                    </div>
                </div>                
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['cart'],
    data() {
        return {
            message: '',
            stickyMessage: '',
            occasion: 'Birthday',
            category: null,
            message_error: false,
            pos: null
        }
    },
    methods: {
        addMessageToItem(item) {
            if(this.message_error) {
                this.$toasted.error(`Please Write Within ${this.maxLength} Letters`)
                return
            }
            item['message'] = this.message;
            item['occasion'] = this.occasion
            this.$router.replace('/cart', ()=>{
                this.$router.go(-1);
            });
        }            
    },
    computed: {
        messageLength: function() {
            if(this.message.length > this.maxLength) {
                this.message_error = true;
            } else {
                this.message_error = false;
            }
            return this.message.length;
        },
        maxLength: function() {
            return this.category == 'Tiffin Cake'? 40 : 25
        }      
    },
    created() {
        this.pos = this.$route.params.pos       
        this.category = this.$route.query.category 
        if(this.cart[this.pos].message) {
            this.message = this.cart[this.pos].message
            this.occasion = this.cart[this.pos].occasion
        }
    }
}
</script>


<style scoped>
textarea {
    width: 100%;
    border: 1px solid #bdbdbd;    
    padding: 15px 10px;    
    box-sizing: border-box;
}

textarea:focus {
    outline: 0;    
}

.red {
    color: red;
}

.btn-message {
    background: #00A651;
    color: white;
    border: 1px solid transparent;
    width: 100%;
    padding: 10px 0px;    
}

h4 {
    margin-top: 0px;
}

</style>
