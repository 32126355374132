<template>
	<div>
	<div v-if="dataLoaded" class="strong center animated fadeIn">
	    <i class="icon-mobile"></i> &nbsp; 
	     <span  class="">Your Phone: +91-{{ phone }} </span> &nbsp;
	     <div style="display: inline-block;padding: 3px;border-radius: 7px;" class="material-card" >
		     <button @click="showModal = true" class="remove-btn-styles">
		      <small><i class="icon-edit-alt"></i></small>
		     </button>           
	     </div>
	</div>
	<div v-else class="flex-row dead-center">
	  <pulse-loader></pulse-loader>
	</div>

	<!-- CHANGE NUMBER MODAL -->
	<modal v-if="showModal" @close="showModal = false">		
		<template v-if="showModalPreloader">			
			<div slot="body" class="flex-row dead-center">
			  <pulse-loader></pulse-loader>
			</div>				
		</template>
		<template v-else>
			<div slot="header">
				<h3>Enter your phone number</h3>
			</div>
			<div slot="body">
				<input type="tel" v-model.number="newPhone" />
			</div>
			<div slot="footer">
				<button class="btn-wide bg-primary text-white" @click="changeNotifyTo">Change</button>
			</div>
		</template>
	</modal>
	</div>	 
</template>

<script>
	import {db} from './../../database'
	import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
	import Modal from './../Modal.vue'
	import AddressValidationMixin from './../../mixins/AddressValidationMixin'


	export default {
		props: ['user'],
		data(){
			return {
				phone: '',
				showModal: false,
				newPhone: '',
				showModalPreloader: false,
				dataLoaded: false
			}
		},
		created(){
			db.doc("users/"+this.user.uid).get().then(doc => {
			    if(doc.exists) {
					this.phone = doc.data()['notify'];
			    }
				this.$emit('getNotifyPhone', this.phone)
				this.dataLoaded = true
			})
		},
		methods: {
			changeNotifyTo(){
				if(!this.checkPhoneNumber(this.newPhone)) {
					this.$toasted.error("Please Enter Your 10 Digit Phone Number");        
					return
				}
				this.showModalPreloader = true
				db.doc("users/"+this.user.uid).update({
					notify: this.newPhone
				}).then(()=>{
					this.phone = this.newPhone
			    	this.$emit('getNotifyPhone', this.newPhone)					
					this.showModalPreloader = false
					this.showModal = false
				})
			}
		},
		components: {
			'pulse-loader': BeatLoader,
			'modal': Modal
		},
		mixins: [AddressValidationMixin]
	}
</script>