<template>
    <div>
        <div class="container-fluid">
            <div class="row no-gutters">
                <div class="col-12 center">
                    <h3 class="center heading-text">Our Coverage in {{ getUserCity }} </h3>
                </div>
            </div>
            <div class="row no-gutters material-card bg-white">
                <div class="col-12">
                    <p v-for="(location,index) in locations" v-bind:key="index">
                        {{ location }}
                    </p>                                      
                </div>
            </div>
            <div class="row no-gutters material-card bg-white">
                <div class="col-12">
                    <h3>Your area not listed?</h3>                                   
                    <p>
                        Give us a call/text at 7578025236 and we'll try to make an exception for you! :-)
                    </p>
                </div>
            </div>            
        </div>

    </div>
</template>

<script>
import {bus} from './../main'
import axios from "axios";
import AddressValidationMixin from "./../mixins/AddressValidationMixin";

export default {
    data(){
        return {
            locations: []
        }
    },
   
    created(){
          axios        
        .get("https://cherryfroststore.df.r.appspot.com/available-locations")
        .then((response) => {
        //   console.log(response.data);
          this.locations = response.data.results.sort()
          this.$nextTick(() => {
            bus.$emit('show_loading', false)        
          })
        })    
    },
    mixins: [AddressValidationMixin],
}
</script>
