  <template>
  <div class="full-height wrapper">
    <div id="checkout-wrap" class="full-height flex-column" style="margin-top: 10px;">
        <div class="center bg-white material-card mb-10" style="padding-top:20px;padding-bottom:0px;">
          <div ref="orderSuccess">            
            <div style="background-color:#f5f6fa;display:inline-block;padding:15px;border-radius:35%;">
                <img class="animated" v-bind:class="{'swing': swingBag}" src="./../assets/order.png">
            </div>
            <br>
              <h3 class="text-success animated fadeIn">
                | &nbsp; ORDER PLACED &nbsp; |
              </h3>
          </div>                          
        </div>

        <div class="container bg-white animated fadeIn material-card mb-10">
            <div class="row" style="padding-top:15px;">
                <div class="col">
                    <strong> <i class=""></i> Items in your order</strong>
                    <p>
                        <span :key="`${item.id}-${index}`" v-for="(item,index) in cartItems">
                            <span v-if="index!=0">, </span> {{ item.name }} ({{ item.flavour }}, {{ item.weight }}) x {{ item.qty }} 
                        </span>
                    </p>
                    <p>
                        Total: &#8377;{{ cartTotal }}                        
                    </p>
                </div>
            </div>
            <div class="row card-lite">
                <div class="col center">
                    <!-- <button @click="$router.replace('/account')" class="remove-btn-styles bg-success text-white" style="padding: 5px 10px;border-radius: 7px;letter-spacing: 2px;">                        
                      CHECK ORDER STATUS <i class="icon-right"></i>           
                    </button> -->
                    <button @click="formOrderText" class="remove-btn-styles bg-success text-white" style="padding: 5px 10px;border-radius: 7px;letter-spacing: 2px;cursor:pointer;">                        
                      PROCEED TO PAYMENT <i class="icon-right"></i>           
                    </button>
                    <p>
                      Payment is necessary in order to confirm the order
                    </p>
                </div>
            </div>            
        </div>

        <!-- ***************************** HANDLE ORDER SAVING *********************************** -->
        <div v-if="savingOrder">
          <p class="center">
            <pulse-loader></pulse-loader>
          </p>
        </div>        
        <div v-else class="container card-lite animated pulse material-card" style="padding-top: 10px;">
            <div class="row center align-items-center">
                <div class="col-12" style="text-align: left;">
                    <strong> <i class=""></i> You may also save the order as </strong>
                </div>
            </div>
            <div class="flex-row space-around center bg-white text-white" style="padding-top: 15px;padding-bottom: 15px;">
                <div class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background-color: #3498db;padding-top: 10px;">
                  <div>
                    <strong>Comfort Box</strong>
                    <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
                  </div>
                <small v-if="savedOrders['comfort']">
                  Saved. <br> <strong class="cursor-pointer" @click="changeSavedOrder('comfort')"> Change? </strong>
                </small>
                <button v-else @click="saveOrder('comfort')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">SAVE</button>
                </div>
                <div class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background: linear-gradient(#00A651,#2ecc71);padding-top: 10px;">
                  <div>
                    <strong>Delight Box</strong>
                    <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
                  </div>
                 <small v-if="savedOrders['delight']">
                   Saved. <br> <strong class="cursor-pointer" @click="changeSavedOrder('delight')"> Change? </strong>
                 </small>
                <button v-else @click="saveOrder('delight')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">SAVE</button>
                </div>
                <div class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background: linear-gradient(#d35400,#e67e22);">
                  <div>
                    <strong>Temptation Box</strong>
                    <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
                  </div>
                <small v-if="savedOrders['temptation']" >
                  Saved. <br> <strong class="cursor-pointer" @click="changeSavedOrder('temptation')"> Change? </strong>
                </small>                
                <button v-else @click="saveOrder('temptation')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">SAVE</button>
                </div>                          
            </div>            
            <br>
        </div>        
        <div class="container" style="background-color: #f5f6fa;">
            <div class="row">
                <div class="col grey">
                    <p>
                        <strong>Please Note</strong>
                    </p>
                    <p> <small>
                        Orders once placed cannot be cancelled
                    </small> </p>
                    <p> <small>
                        You can order through website again after only after your current order is delivered. Please contact us if you want to place more than one order at a time
                    </small> </p>                    
                    <!-- <p> <small>
                        Payment must be made within 1 hour of placing the order to avoid any possible inconveniences. Your order will not undergo preparation unless payment has been made
                    </small> </p>                     -->
                </div>
            </div>
        </div>
    </div>
    <div v-if="showModal">
      <modal>
        <div slot="header" class="flex-row align-items-center space-between">
          <h3>
            <i class="ion-information-circled"></i> &nbsp; Changing {{ selectedOrderType }}?
          </h3>
            <i @click="$emit('close')" class="icon-cancel-circle text-dark" style="font-size: 16px;"></i>
        </div>
        <div slot="body">
        </div>
        <div slot="footer">
          <button @click="saveOrder(selectedOrderType)" class="btn-wide text-white bg-primary">
            Yes, change
          </button>          
        </div>
      </modal>
    </div>
  </div>
</template>


<script>

import {bus} from './../main'
import {db} from './../database'
import PulseLoader from 'vue-spinner/src/BeatLoader.vue'
import Modal from './../components/Modal'
import imageLoadMixin from './../imageLoadMixin'
import cartMixin from '../cartMixin'

export default {
  beforeRouteEnter (to, from, next) {
    if(from.name == "Deliver To") { next() }
    else { next('/') }
  },  
  props: ['user', 'cart'],
  data(){
    return {
        showModal: false,
        savedOrders: {},
        savingOrder: false,
        orders: {},
        selectedOrderType: '',
        swingBag: false,
        cartTotal: 0,
        cartItems: []
    }
  },
  created() {    
    bus.$emit('show_loading', true);
    this.$on('close', ()=>{ this.showModal = false })   
    this.cartTotal = this.getTotalCost()
    this.cartItems = Object.assign([], this.getCartItems)
    this.emptyCart()                       
    // localStorage.removeItem('cart');        
    db.doc("users/"+this.user.uid).get().then(doc => {
      bus.$emit('checkoutSuccess', doc.data());
      if(doc.data().savedOrders) {
        this.savedOrders = doc.data().savedOrders
      }      
      this.detectImagesLoad().then(data => {
        bus.$emit('show_loading', false)    
        let ref = this.$refs.orderSuccess
        setTimeout(this.changeSwingBagStatus, 700)
      })      
    })
  },
  methods: {
        formOrderText() {      
          let msg = `Request for payment | Order No. ${this.$route.query.oid}`
          window.open('https://wa.me/+917578025236?text='+msg, '_blank')
        },      
        changeSwingBagStatus(){
          this.swingBag = !this.swingBag
        },
        changeSavedOrder(orderType){
          this.selectedOrderType = orderType
          this.showModal = true
        },
        saveOrder(mealType){
          this.$emit('close')          
          this.savedOrders[mealType] = this.getItemsBasicInfo(this.cartItems)
          this.savingOrder = true
          db.doc("users/"+this.user.uid).update({
            savedOrders: this.savedOrders
          }).then(()=> {
            this.savingOrder = false
          }).catch(()=>{
            console.log("error saving order")
          })
        }
  },  
  mixins: [imageLoadMixin, cartMixin],
  components:{
    'pulse-loader': PulseLoader,
    'modal': Modal
  },
  // destroyed() {
  //     bus.$emit('changeCart', []);
  //     localStorage.removeItem('cart');        
  //     this.emptyCart()
  // }
}
</script>
