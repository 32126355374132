<!-- 1 REST CALL TO FETCH AVAILABLE LOCATIONS -->

<template>
	<modal @close="$emit('close')">
	  <div slot="header">
	    <h3>Enter your locality</h3>              
	  </div>
	  <div slot="body">
		    <p style="margin-bottom:0;">
		      <!-- A small distance fee would be applicable. <br><br> -->
          <!-- <strong class="text-success">Promotional Period</strong> | 50% OFF Delivery Fee -->
		    </p> <br>
        <div>
          <input
            v-model="customLocality"            
            placeholder="E.g. - Chandmari, Rihabari, etc"
            type="text"
            autofocus
            style="width:100%;"
          />          
        </div>      
	  </div>
	  <div slot="footer">	    
	    <button @click="confirmCustomLocality" class="btn-wide bg-success text-white">
	        CONFIRM
	    </button>              
	  </div>
	</modal> 
</template>

<script>
import Modal from './../Modal'

export default {
    data(){
        return {
            locationSelected: false,
            success: 'red',
            locations: {},
            selectedLocation: '',
            customLocality: ""
        }
    },
    created(){    	

    },
    methods: {
      confirmCustomLocality() {
        this.$emit('customLocalitySet', this.customLocality)
      }        
    },
    components:{
        'modal': Modal,
    }
}
</script>